import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { App } from "./components/App";
import "./i18n";
import "./assets/css/style.css";

const { REACT_APP_ENV } = process.env;

Sentry.init({
  dsn: "https://d739b70c28ce4147a51e82d7cf5a435c@o389175.ingest.sentry.io/5337181",
  environment: REACT_APP_ENV,
});

ReactDOM.render(
  <BrowserRouter>
    <Route path="/:dashboard_type/:entity_id/">
      <App />
    </Route>
  </BrowserRouter>,
  document.getElementById("root")
);

declare global {
  interface Window {
    analytics: any;
  }
}
