import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeaderType } from "../types";
import spendesk_logo from "../assets/images/spendesk_logo.svg";

export const HeaderNavBar = ({ entity_id, profile_pic_url }: HeaderType) => {
  let { pathname: urlPath } = useLocation();
  const { t } = useTranslation();

  let baseClassName = "App__header-link-left";

  useEffect(() => {}, [entity_id, urlPath]);

  return (
    <header className="App__header">
      <a href={`https://spendesk.com/app/${entity_id}`}>
        <img
          src={spendesk_logo}
          className="Header__logo-image"
          alt="Spendesk"
        />
      </a>
      <Link
        className={`${baseClassName} ${
          urlPath.includes("control") ? "App__header-link-left-selected" : ""
        }`}
        to={`/control/${entity_id}/`}
        key="control"
      >
        {t("header-control")}
      </Link>
      <Link
        className={`${baseClassName} ${
          urlPath.includes("spend_trends")
            ? "App__header-link-left-selected"
            : ""
        }`}
        to={`/spend_trends/${entity_id}/`}
        key="spend_trends"
      >
        {t("header-analytics")}
      </Link>
      <div className="Header__profile">
        <a href={`https://spendesk.com/app/${entity_id}`}>
          <img
            src={profile_pic_url}
            className="Header__profile-pic"
            alt="Spendesk profile"
          />
        </a>
      </div>
    </header>
  );
};
