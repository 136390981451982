import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Moment from "moment";
import { DateRange, extendMoment } from "moment-range";
import DateRangePicker from "react-daterange-picker";
import { DatePickerProps } from "../../types";
import "../../assets/css/calendar.css";

const moment = extendMoment(Moment);

export const DatePicker = (props: DatePickerProps) => {
  const firstDayLast6Months = moment(new Date())
    .subtract("months", 5)
    .startOf("month");
  const lastDayLast6Months = moment(new Date()).add("months", 1).date(0);
  const range = moment.range(firstDayLast6Months, lastDayLast6Months);
  const endOfMonth = moment(new Date()).add("months", 1).date(0).toDate();

  const { t } = useTranslation();

  const [isOpen, openDatePicker] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<DateRange>(range);

  const handleCalendarSelection = (selectedDateRange: DateRange) => {
    setSelectedDate(selectedDateRange);
    props.getDate(selectedDateRange);
    openDatePicker(false);
  };

  const closeCalendarOnPressingEscape = useCallback((event) => {
    if (event.keyCode === 27) {
      openDatePicker(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", closeCalendarOnPressingEscape, false);

    return () => {
      document.removeEventListener(
        "keydown",
        closeCalendarOnPressingEscape,
        false
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="DatePicker_container">
      <div className="DatePicker_explanation_text">
        {t("date-picker-explanation")}
      </div>
      <div className="DatePicker">
        <div>
          <input
            className="calendar-input"
            value={moment(selectedDate.start.toString()).format("D MMM YYYY")}
            onClick={() => openDatePicker(true)}
            readOnly
          />
          <i className="fas fa-chevron-right"></i>
          <input
            className="calendar-input"
            value={moment(selectedDate.end.toString()).format("D MMM YYYY")}
            onClick={() => openDatePicker(true)}
            readOnly
          />
        </div>
        <div
          className={``}
          style={{ visibility: isOpen ? "visible" : "hidden" }}
        >
          <DateRangePicker
            onSelect={(selectedDateRange: DateRange) =>
              handleCalendarSelection(selectedDateRange)
            }
            value={selectedDate}
            className="calendar"
            numberOfCalendars={2}
            firstOfWeek={1}
            maximumDate={endOfMonth}
            initialRange={selectedDate}
            locale={moment.locale()}
          />
        </div>
      </div>
    </div>
  );
};
