import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SpendeskAnalyticsURLParameters, UserData, SelectedDashboard } from "../types";
import axios from "axios";
import * as Moment from "moment";
import { DateRange, extendMoment } from "moment-range";
import { SHA256 } from 'crypto-js';

import { HeaderNavBar } from "./HeaderNavBar";
import { DatePicker } from "./utils/DatePicker";
import { Placeholder } from "./utils/Placeholder";
import { renderLoader } from "./utils/Loader";

import avatar from "../assets/images/avatar-f.svg";

const moment = extendMoment(Moment);


export const App = () => {
  const { REACT_APP_SERVER_URL, REACT_APP_SALT } = process.env;

  const { entity_id, dashboard_type } =
    useParams<SpendeskAnalyticsURLParameters>();

  const { i18n } = useTranslation();

  const [profilePicURL, setProfilePicURL] = useState<string>(avatar);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [selectedDashboard, setSelectedDashboard] =
    useState<SelectedDashboard | null>(null);
  const [loadingState, setLoadingState] = useState<boolean | undefined>(true);

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    sendSegmentPage();
    setLoadingState(true);
    setSelectedDashboard(null);
    checkIfLoggedAndLoadData();
    loadDashboardData();
    // eslint-disable-next-line
  }, [dashboard_type, entity_id, userData]);

  const checkIfLoggedAndLoadData = async () => {
    if (!userData) {
      try {
        const { status, data } = await axios.get(
          `https://www.spendesk.com/api/me`,
          {
            withCredentials: true,
          }
        );

        if (status !== 200) {
          redirectToSpendeskLogin();
        } else {
          let { lang, avatar, id: userId } = data.user;
          if (lang === "fr") changeInterfaceToFrench();
          identifyUser(userId, entity_id);
          setProfilePicURL(avatar);
          setUserData({ lang, avatar, userId });
        }
      } catch {
        redirectToSpendeskLogin();
      }
      return;
    }
    return;
  };

  const sendSegmentPage = () => {
    return window.analytics.page(dashboard_type, {
      entity_id: entity_id,
    });
  }

  const changeInterfaceToFrench = (): void => {
    i18n.changeLanguage("fr-FR");
    require(`moment/locale/fr`);
    Moment.locale("fr");
  };

  const identifyUser = (user_id: string, company_id: string): void => {
    return window.analytics.identify(user_id, {
      company_id,
    });
  };

  const returnSafariPlaceholder = (): JSX.Element => {
    return (
      <div className="not_compatible">
        <div>
          Spendesk Analytics doesn't run on Safari{" "}
          <span role="img" aria-label="sad">
            😣
          </span>
          <br /> Please use Chrome or Firefox to browse Spendesk Analytics
        </div>
        <a href="https://spendesk.com/app" className="Default__error-subtitle">
          Back to Spendesk
        </a>
      </div>
    );
  };

  const redirectToSpendeskLogin = (): void => {
    return window.location.assign(
      `https://spendesk.com/auth/login?targetUrl=${window.location.href}`
    );
  };

  const changeDateFiltersInLookerIframe = (date: DateRange): void => {
    let { start, end } = date;
    let formattedStart = moment(start).format("YYYY-MM-DD");
    let formattedEnd = moment(end).add(1,'days').format("YYYY-MM-DD");

    var dashboard_update = JSON.stringify({
      type: "dashboard:filters:update",
      filters: {
        Date: `${formattedStart} to ${formattedEnd}`,
      },
    });

    var refresh_request = JSON.stringify({ type: "dashboard:run" });
    var iframe2: HTMLIFrameElement = document.getElementById(
      "iframe"
    ) as HTMLIFrameElement;
    var iWindow2 = iframe2.contentWindow;
    iWindow2?.postMessage(dashboard_update, "https://spendesk.eu.looker.com");
    iWindow2?.postMessage(refresh_request, "https://spendesk.eu.looker.com");
  };

  const createSignature = (urlParams:string) => {
      const concatenatedString = urlParams + `&salt=${REACT_APP_SALT}`;
      return SHA256(concatenatedString).toString()
    };

  const loadDashboardData = async () => {
    const urlParams = `user_id=${userData?.userId}&entity_id=${entity_id}&spendesk_analytics_dashboard_type=${dashboard_type}`
    const signature = createSignature(urlParams)
    const { status, data: dashboardURL } = await axios.get<string>(
      `${REACT_APP_SERVER_URL}/spendesk-analytics-dashboard?${urlParams}`,
      {
        headers: {
            signature: signature,
        },
        validateStatus: () => true
      }
    );

    if (status === 200) {
      setSelectedDashboard({
        has_rights: true,
        dashboard_url: dashboardURL,
      });
    } else if (status === 403 || status === 401) {
      setSelectedDashboard({
        has_rights: false,
        dashboard_url: null,
      });
    }

    setLoadingState(false);

    return;
  };

  const setDashboard = () => {
    if (userData && selectedDashboard) {
      if (selectedDashboard?.has_rights) {
        return (
          <div style={{ height: "100%" }}>
            <DatePicker
              getDate={(selectedDateRange: DateRange) =>
                changeDateFiltersInLookerIframe(selectedDateRange)
              }
            />
            <div className="App__content_home_iframe">
              <iframe
                className="dashboard-iframe"
                title="Looker iframe"
                width="100%"
                id="iframe"
                height="100%"
                frameBorder="0"
                src={selectedDashboard?.dashboard_url!}
                onLoad={() => {
                  setLoadingState(false);
                }}
              />
            </div>
          </div>
        );
      } else if (!selectedDashboard?.has_rights) {
        return <Placeholder />;
      } else {
        return <div></div>;
      }
    } else renderLoader();
  };

  if (isSafari) {
    return returnSafariPlaceholder();
  } else {
    return (
      <div className="App">
        <HeaderNavBar entity_id={entity_id} profile_pic_url={profilePicURL} />
        {loadingState ? renderLoader() : null}
        <main className="App__container">
          <div className="App__content_home">{setDashboard()}</div>
        </main>
      </div>
    );
  }
};
