import React from "react";
import i18n from "i18next";

export const renderLoader = () => {
  return (
    <div className="loader">
      <div className="loader-text">
        <i className="fas fa-circle-notch fa-spin icon-loader"></i>
        {i18n.t("loader-label")}
      </div>
    </div>
  );
};
