import React from "react";
import { useTranslation } from "react-i18next";

import placeholder_image from "../../assets/images/placeholder_image.png";

export const Placeholder = () => {
  const { t } = useTranslation();

  return (
    <div className="placeholder-page">
      <div className="placeholder">
        <div className="placeholder-title">{t("placeholder-title")}</div>
        <div className="placeholder-subtitle">{t("placeholder-subtitle")}</div>
        <div className="placeholder-separator" />
        <img
          src={placeholder_image}
          className="placeholder-control-placeholder"
          alt="Discover Spendesk Analytics"
        />
      </div>
    </div>
  );
};
